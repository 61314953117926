

/* Hero */
section.hero {
    .hero-inner {
        /* FIXME: will need to account for nav bar later */
        min-height: 100vh; 

        padding: 0 60px;

        @media all and (width <= 800px) {
            padding: 0 20px;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .text {
            text-align: center;
            color: var(--light);

            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .logo-holder {
            /* flex: 1 1 605px; */

            img {
                @media (width <= 800px) {
                    max-width: 350px;
                }
            }
        }
    }
}
